
import { defineComponent, ref } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Slider from '../../../components/Slider.vue';
import SliderItem from '../../../components/SliderItem.vue';

import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Jobvielfalt in der STYRIA | Styria Media Group',
      keywords: ['berufsbild', 'jobvielfalt', 'mitarbeiter', 'jobangebote', 'traumjob'],
      description: 'Die STYRIA bietet eine Vielfalt an Jobs: Radiomoderator:in, Grafikdesigner:in, Reporter:in, Projektmanager:in, Datenjournalist:in und vieles mehr.',
      image: require('@/assets/img/og/karriere.png'),
    },
    en: {
      title: 'Job variety | Styria Media Group',
      keywords: ['job profile', 'job diversity', 'employee', 'job offer', 'dream job', 'styria'],
      description: 'STYRIA offers a variety of jobs: radio host, graphic designer, reporter, project manager, data journalist and much more.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
    Slider,
    SliderItem,
  },
  setup() {
    const active = ref(18);

    const setActive = (value: number) => {
      active.value = value;
    };
    return {
      setActive,
      active,
      mdiChevronUp,
      mdiFormatQuoteClose,
    };
  },
});
