<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .berufs-bilder-all {
    .header-intro-generel-box {
      background-position: center center !important;
    }

    .top-section {
      .img-box-outer {
        position: relative;
        margin-bottom: 280px;
        @media (max-width: $width-lg) {
          margin-bottom: 20px;
        }
        .img-box-header-img {
          padding: 20px;
          border: 10px solid $violet-50per;
        }
        .img-box-inner {
          width: 100%;
          height: 600px;
          background-size: cover;
          background-position: center center;
        }
        .box-left {
          border: 10px solid $blue-60per;
          display: inline-block;
          background: #fff;
          padding: 40px 70px;
          text-align: center;
          position: absolute;
          left: 50px;

          bottom: -200px;
          max-width: 440px;
          @media (max-width: $width-lg) {
            position: relative;
            bottom: 0;
            left: 0;
            margin: 30px auto 0 auto;
            display: block;
          }

          h3 {
            color: $coral;
            margin-top: 30px;
            font-size: 140%;
            font-family: 'American Typewriter ITC W04 Md';
            line-height: 1.5;
            text-transform: none;
          }
          .txt-small {
            color: $coral;
            margin-top: 120px;
            letter-spacing: $letter-spacing-std;
          }
        }
        .box-right-anima {
          position: absolute;
          right: 50px;
          top: -50px;
          background: $mint;
          display: inline-block;
          padding: 30px 15px;
          min-width: 280px;
          @media (max-width: $width-lg) {
            top: -25px;
          }
          @media (max-width: $width-sm) {
            right: 50%;
            margin-right: -140px;
          }

          .txt-v1,
          .txt-v2 {
            margin-top: 20px;
            text-align: center;
            padding: 15px;
          }
          .txt-v1 {
            font-family: 'American Typewriter ITC W04 Md';
            font-size: 160%;
          }
          .txt-v2 {
            letter-spacing: $letter-spacing-std;
          }
          .img-box {
            padding: 20px;
            position: relative;

            img {
              display: block;
              margin: 10px auto 0 auto;
              max-width: 200px;
              width: 100%;
              animation: slogan-rotating 15s linear infinite;
            }
            @keyframes slogan-rotating {
              from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
              }
              to {
                -ms-transform: rotate(-360deg);
                -moz-transform: rotate(-360deg);
                -webkit-transform: rotate(-360deg);
                -o-transform: rotate(-360deg);
                transform: rotate(-360deg);
              }
            }
          }
        }
      }
    }

    .dropdown {
      position: relative;
      @media (max-width: $width-sm) {
        //text-align:center;
        margin: 0 auto;
        max-width: 90%;
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #000;
        position: absolute;
        top: 50%;
        z-index: -1;
      }
      .dropdown-toggle {
        //border:1px solid green;
        //margin:0 auto;
        padding: 15px 40px;

        letter-spacing: 1px;
        min-width: 200px;
        display: inline-block;
        border: 1px solid #000;
        text-transform: uppercase;
        font-size: 100%;
        background: #fff;
        font-family: 'Helvetica Neue LT W05 65 Medium';
        letter-spacing: $letter-spacing-std;
        @media (max-width: $width-sm) {
        }
        @media (max-width: $width-lg) {
          font-size: 110%;
        }

        //border-bottom:0;
        &:after {
          position: relative;
          top: 2px;
          left: 5px;
        }
        &:hover {
          color: #000;
          opacity: 1;
          background: $blue-60per;
        }
      }
      .dropdown-menu {
        max-height: 250px;
        min-width: 500px;
        left: 0px !important;
        border-radius: 0;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 5px;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
          outline: 1px solid $grey;
          border-radius: 5px;
        }
        @media (max-width: $width-md) {
          min-width: 100%;
          max-width: 100%;
        }

        li {
          .btn-tab {
            text-align: left;
            width: 100%;
            display: block;
            border: 0;
            padding: 8px 10px;
            background: none;
            letter-spacing: 1px;
            &:hover {
              background: #eee;
            }
          }
          .active {
            background: $coral;
            color: #fff;
            &:hover {
              background: $coral;
              color: #fff;
            }
          }
        }
      }
    }

    .tab-content-all {
      //border:2px solid blue;
      .tab-inner {
        //border:1px solid red;
      }
    }
    .berufsbilder-box {
      //border:1px solid blue;
      max-width: 80%;
      margin: 30px auto;
      @media (max-width: $width-md) {
        max-width: 90%;
      }
      h2 {
        text-align: center;
        margin: 0 0 20px 0;

        padding: 0 30px;
        font-size: 200%;
        @media (max-width: $width-lg) {
          padding: 0;
          font-size: 180%;
        }

        @media (max-width: $width-lg) {
          font-size: 160%;
        }
      }
      span {
        display: block;
        text-align: center;
        margin-bottom: 50px;
        letter-spacing: $letter-spacing-std;
        text-transform: uppercase;
      }
      h3 {
        font-family: 'Helvetica Neue LT W05_75 Bold';
        text-transform: none;
        font-size: 140%;
      }
      .img-box {
        position: relative;
      }
      h4 {
        font-family: 'American Typewriter ITC W04 Md';
        line-height: 1.5;
        text-align: center;
        text-transform: none;
        font-size: 180%;
        position: absolute;
        padding: 30px;
        max-width: 450px;
        @media (max-width: $width-lg) {
          font-size: 140%;
        }

        .zitat-icon {
          display: block;
          margin: 0 auto;
        }
        .zitat-icon-fff {
          fill: #fff;
        }
        .name {
          display: block;
          margin-bottom: 0;
          margin-top: 10px;
          text-transform: uppercase;
          font-family: 'Helvetica Neue LT W05 65 Medium';
          font-size: 16px;
          letter-spacing: 2px;
          .firma {
            display: block;
            font-size: 14px;
            margin-top: 5px;
            font-family: 'Helvetica Neue LT W05 45 Light';
            margin-bottom: 0;
            letter-spacing: 2px;
          }
        }

        @media (max-width: $width-xl) {
          position: relative;
          margin-bottom: 50px;
          max-width: 100%;
          text-align: center;
        }
      }
      .big-h4 {
        max-width: 500px;
      }
      .top-left {
        top: 150px;
        left: -100px;
        @media (max-width: $width-xl) {
          top: 0;
          left: 0;
        }
      }
      .top-right {
        top: 150px;
        right: -100px;
        @media (max-width: $width-xl) {
          top: 0;
          right: 0;
        }
      }
      .bottom-left {
        bottom: 150px;
        left: -100px;
        @media (max-width: $width-xl) {
          bottom: 0;
          left: 0;
        }
      }
      .bottom-right {
        bottom: 150px;
        right: -100px;
        @media (max-width: $width-xl) {
          bottom: 0;
          right: 0;
        }
      }
      p {
        margin-bottom: 30px;
      }
    }
    .fade-tab-enter-active,
    .fade-tab-leave-active {
      transition: all 1s ease;
      //transform: translateY(100px);
    }
    .fade-tab-enter-active {
      transform: translateY(0px);
      opacity: 1;
    }
    .fade-tab-leave-active {
      transform: translateY(100px);
    }

    .fade-tab-enter-from,
    .fade-tab-leave-to {
      transition: all 0.5s ease;
      opacity: 0;
    }

    .fade-tab-enter-from {
      transform: translateY(200px);
    }

    .back-to-slider {
      display: inline-block;
      margin-top: 100px;
      padding: 0 30px;

      border: 1px solid #000;

      svg {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      span {
        display: block;
        letter-spacing: 1px;
      }
      &:hover {
        color: #000;
        opacity: 0.7;
      }
    }
    .img-box-slogan {
      padding: 20px;
      position: relative;

      @media (max-width: $width-md) {
        padding: 40px 20px;
      }
      img {
        display: block;
        margin: 0 auto;
        max-width: 200px;
        width: 100%;
        animation: slogan-rotating 15s linear infinite;
      }
      @keyframes slogan-rotating {
        from {
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -ms-transform: rotate(-360deg);
          -moz-transform: rotate(-360deg);
          -webkit-transform: rotate(-360deg);
          -o-transform: rotate(-360deg);
          transform: rotate(-360deg);
        }
      }
    }
    .berufs-bilder-navi-slider {
      //border:1px solid red;
      margin-bottom: 30px;
      margin-top: 50px;
      .slidercontainer {
        //border:1px solid blue;

        position: relative;
        .slider-navi-outer {
          position: relative;
          text-align: center;
          padding-bottom: 40px;
          margin-left: 15px;
          margin-right: 15px;
          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #000;
            position: absolute;
            top: 20px;
          }
          div {
            display: inline-block;
            position: relative;
            &:after {
              content: '';
              display: block;
              width: 40px;
              height: 40px;
              background: #fff;
              position: absolute;
              top: 0;
              left: 15px;
            }
          }
          .arrow-btn {
            width: 40px;
            height: 40px;
            display: inline-block;
            margin: 0 15px;
            cursor: pointer;
            background-color: #fff;
            position: relative;
            z-index: 1;
            &:hover {
              opacity: 0.7;
            }
          }
          .arrow-left {
            transform: rotate(-180deg);
          }
          .arrow-right {
          }
        }

        .slideritem {
          //border:1px solid green;
          padding: 0 15px;
          .img-box-outer {
            transition: $transition-std;
            position: relative;
            border-width: 5px;
            border-style: solid;
            border-left: 0;
            border-right: 0;
            border-top: 0;
            .img-box-inner {
              width: 100%;
              height: 250px;
              background-size: cover;

              background-position: center center;
            }
            .img-title {
              height: 150px;
              text-align: center;
              padding: 10px 20px;
              font-family: 'Helvetica Neue LT W05 96 Blk It';
              font-size: 110%;
              text-transform: uppercase;
              span {
                display: block;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            &:hover {
              cursor: pointer;
              opacity: 0.8;
              transform: scale(0.95);
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="berufs-bilder-all">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb">
                <span>{{ $t('General.karriere') }}</span>
              </div>
              <h1 v-html="$t('General.jobvielfalt')"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-baelle.jpg')})` }"  headerImageText="" />-->
    <div class="container top-section">
      <div class="row">
        <div class="col-12">
          <div class="img-box-outer">
            <div class="img-box-header-img">
              <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-baelle.jpg')})` }"></div>
            </div>
            <div class="row justify-content-center align-self-center">
              <div class="col-lg-6 align-self-center">
                <div class="box-left" v-inview="['animation-delay-0-5s', 'move-in-from-top']">
                  <h3 v-html="$t('General.unsere-mission-text')"></h3>
                  <div class="txt-small">{{ $t('General.unsere-mission-title') }}</div>
                </div>
              </div>
              <div class="col-lg-6 align-self-center">
                <div class="box-right-anima">
                  <div class="txt-v1" v-html="$t('General.we-are-as-good')"></div>
                  <div class="img-box"><img src="@/assets/img/ueber-uns/slogan-one-spirit-red.svg" alt="One spirit - unlimited ideas" class="img-fluid" /></div>
                  <div class="txt-v2" v-html="$t('General.unser-human-title')"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row justify-content-center padding-t-m padding-b-m">
        <div class="col-lg-6 col-md-7">
          <p class="intro-txt text-center font-helv-medium padding-b-s">{{ $t('berufs-bilder.intro-txt') }}</p>
          <div class="img-box-slogan" v-inview="['animation-delay-0-5s', 'move-in-from-bottom']"><img src="@/assets/img/ueber-uns/slogan-one-spirit-red.svg" alt="One spirit - unlimited ideas" class="img-fluid" /></div>

          <p class="intro-txt text-center font-helv-medium padding-t-s">{{ $t('berufs-bilder.intro-txt-v2') }}</p>
          <div class="btn-std">
            <router-link :to="{ name: 'offene-positionen' }"
              ><span class="txt" v-html="$t('berufs-bilder.btn-intro')"> </span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- -->
          <div class="berufs-bilder-navi-slider">
            <Slider :delay="4000" :items="{ 768: 2, 1024: 3, 1400: 4, 1600: 4 }" easing="linear" :speed="500" autoplay noclone>
              <template #navigation="{ left, right }">
                <div class="slider-navi-outer">
                  <div @click="right"><img src="@/assets/img/slider-arrow-right.svg" alt="Pfeil Links" class="img-fluid arrow-btn arrow-left" /></div>
                  <div @click="left"><img src="@/assets/img/slider-arrow-right.svg" alt="Pfeil Rechts" class="img-fluid arrow-btn arrow-right" /></div>
                </div>
              </template>

              <template #items>
                <SliderItem>
                  <div class="img-box-outer border-color-mint">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/sarah-ruckhofer.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.reporterin-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 1 }" @click="setActive(1)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-blue-60perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/johanna-weiermann.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.produkmanagement-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 2 }" @click="setActive(2)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-violet-50perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/alex-supper.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.keyaccountmanager-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 3 }" @click="setActive(3)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-coral">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/yasemin-erdem.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.servicedesk-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 4 }" @click="setActive(4)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-mint">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/maria-schwarz.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.businesscontroller-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 5 }" @click="setActive(5)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-blue-60perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/jonas-pregartner.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.datenjournalist-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 6 }" @click="setActive(6)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                
                <SliderItem>
                  <div class="img-box-outer border-color-violet-50perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/dominik-schmied.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.projektmanager-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 7 }" @click="setActive(7)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                
                <SliderItem>
                  <div class="img-box-outer border-color-coral">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/lukas-kohlmair.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.videocutter-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 8 }" @click="setActive(8)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-mint">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/klaus-krall.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.regionalverkaufer-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 9 }" @click="setActive(9)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <!--
                <SliderItem>
                  <div class="img-box-outer border-color-blue-60perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/benedikt-sassmann.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.leserervice-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 10 }" @click="setActive(10)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                -->
                <SliderItem>
                  <div class="img-box-outer border-color-violet-50perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/larissa-eberhardt.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.redakteur-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 11 }" @click="setActive(11)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-coral">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/simone-ruchesi.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.grafikdesigner-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 12 }" @click="setActive(12)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-mint">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/bernhard-benke.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.frontenddeveloper-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 13 }" @click="setActive(13)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <!--
                <SliderItem>
                  <div class="img-box-outer border-color-blue-60perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/vanessa-waltenspiel.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.onlinemarketing-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 14 }" @click="setActive(14)" class="stretched-link"></a>
                  </div>
                </SliderItem>-->
                <SliderItem>
                  <div class="img-box-outer border-color-violet-50perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/thomas-plauder.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.printeditor-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 15 }" @click="setActive(15)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-coral">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/martin-wimberger.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.telefonverkaeufer-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 16 }" @click="setActive(16)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-mint">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/julian-friedl.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.radiomoderator-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 17 }" @click="setActive(17)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-blue-60perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/matthias-dungel.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.adoperationmanager-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 18 }" @click="setActive(18)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-violet-50perc">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/karina-kleewein.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.kreditorenbuchhalter-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 19 }" @click="setActive(19)" class="stretched-link"></a>
                  </div>
                </SliderItem>
                <SliderItem>
                  <div class="img-box-outer border-color-coral">
                    <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/karriere/david-matausek.jpg')})` }"></div>
                    <div class="img-title">
                      <span>{{ $t('berufs-bilder.informationsecurityofficer-hl') }}</span>
                    </div>
                    <a href="#tab-berufs-bilder" v-smooth-scroll="{ offset: -100 }" :class="{ active: active == 20 }" @click="setActive(20)" class="stretched-link"></a>
                  </div>
                </SliderItem>
              </template>
            </Slider>
          </div>
          <!-- -->
        </div>
      </div>
    </div>
    <div class="container margin-t-s">
      <div class="row justify-content-center" id="slider-beruf-top">
        <div class="col-12 align-self-center">
          <div class="dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">{{ $t('berufs-bilder.berufsbild-waehlen-btn') }}</a>
            <ul class="dropdown-menu">
              <li>
                <button class="btn-tab" :class="{ active: active == 18 }" @click="setActive(18)">{{ $t('berufs-bilder.adoperationmanager-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 5 }" @click="setActive(5)">{{ $t('berufs-bilder.businesscontroller-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 6 }" @click="setActive(6)">{{ $t('berufs-bilder.datenjournalist-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 13 }" @click="setActive(13)">{{ $t('berufs-bilder.frontenddeveloper-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 12 }" @click="setActive(12)">{{ $t('berufs-bilder.grafikdesigner-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 4 }" @click="setActive(4)">{{ $t('berufs-bilder.servicedesk-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 20 }" @click="setActive(20)">{{ $t('berufs-bilder.informationsecurityofficer-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 3 }" @click="setActive(3)">{{ $t('berufs-bilder.keyaccountmanager-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 19 }" @click="setActive(19)">{{ $t('berufs-bilder.kreditorenbuchhalter-hl-top-v2') }}</button>
              </li>
              <!--
              <li>
                <button class="btn-tab" :class="{ active: active == 10 }" @click="setActive(10)">{{ $t('berufs-bilder.leserervice-hl') }}</button>
              </li>
              -->
              <!--
              <li>
                <button class="btn-tab" :class="{ active: active == 14 }" @click="setActive(14)">{{ $t('berufs-bilder.onlinemarketing-hl') }}</button>
              </li>
              -->
              <li>
                <button class="btn-tab" :class="{ active: active == 15 }" @click="setActive(15)">{{ $t('berufs-bilder.printeditor-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 2 }" @click="setActive(2)">{{ $t('berufs-bilder.produkmanagement-hl') }}</button>
              </li>
           
              <li>
                <button class="btn-tab" :class="{ active: active == 7 }" @click="setActive(7)">{{ $t('berufs-bilder.projektmanager-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 17 }" @click="setActive(17)">{{ $t('berufs-bilder.radiomoderator-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 11 }" @click="setActive(11)">{{ $t('berufs-bilder.redakteur-hl') }}</button>
              </li>

              <li>
                <button class="btn-tab" :class="{ active: active == 9 }" @click="setActive(9)">{{ $t('berufs-bilder.regionalverkaufer-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 1 }" @click="setActive(1)">{{ $t('berufs-bilder.reporterin-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 16 }" @click="setActive(16)">{{ $t('berufs-bilder.telefonverkaeufer-hl') }}</button>
              </li>
              <li>
                <button class="btn-tab" :class="{ active: active == 8 }" @click="setActive(8)">{{ $t('berufs-bilder.videocutter-hl') }}</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="tab-content-all" id="tab-berufs-bilder">
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 1">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/sarah-ruckhofer.jpg" :alt="$t('berufs-bilder.reporterin-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-right bg-mint">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.reporterin-zitat') }}<span class="name">Sarah Ruckhofer<span class="firma">Kleine Zeitung</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.reporterin-hl') }}</h2>
                      <span>Sarah Ruckhofer, Kleine Zeitung</span>
                      <h3>{{ $t('berufs-bilder.reporterin-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.reporterin-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.reporterin-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.reporterin-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.reporterin-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.reporterin-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.reporterin-hl-v4') }}</h3>
                      <p>{{ $t('berufs-bilder.reporterin-txt-v4') }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 2">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/johanna-weiermann.jpg" :alt="$t('berufs-bilder.produktentwicklung-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-right bg-blue-60perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.produktentwicklung-zitat') }}<span class="name">Johanna Weiermann<span class="firma">Die Presse</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2 v-html="$t('berufs-bilder.produktentwicklung-hl')"></h2>
                      <span>Johanna Weiermann, Die Presse</span>
                      <h3>{{ $t('berufs-bilder.produktentwicklung-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.produktentwicklung-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.produktentwicklung-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.produktentwicklung-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.produktentwicklung-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.produktentwicklung-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.produktentwicklung-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.produktentwicklung-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 3">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/alex-supper.jpg" :alt="$t('berufs-bilder.keyaccountmanager-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="bottom-left bg-violet-50perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.keyaccountmanager-zitat') }}<span class="name">Alexandra Supper<span class="firma">Die Presse</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.keyaccountmanager-hl') }}</h2>
                      <span>Alexandra Supper, Die Presse</span>
                      <h3>{{ $t('berufs-bilder.keyaccountmanager-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.keyaccountmanager-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.keyaccountmanager-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.keyaccountmanager-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.keyaccountmanager-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.keyaccountmanager-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.keyaccountmanager-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.keyaccountmanager-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 4">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/yasemin-erdem.jpg" :alt="$t('berufs-bilder.servicedesk-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-right bg-coral color-fff">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon zitat-icon-fff" />{{ $t('berufs-bilder.servicedesk-zitat') }}<span class="name">Yasemin Erdem<span class="firma">Styria IT Solutions</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.servicedesk-hl') }}</h2>
                      <span>Yasemin Erdem, Styria IT Solutions</span>
                      <h3>{{ $t('berufs-bilder.servicedesk-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.servicedesk-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.servicedesk-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.servicedesk-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.servicedesk-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.servicedesk-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.servicedesk-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.servicedesk-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 5">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/maria-schwarz.jpg" :alt="$t('berufs-bilder.businesscontroller-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-right bg-mint">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.businesscontroller-zitat') }}<span class="name">Maria Schwarz<span class="firma">Styria Media Group</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.businesscontroller-hl') }}</h2>
                      <span>Maria Schwarz, Styria Media Group</span>
                      <h3>{{ $t('berufs-bilder.businesscontroller-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.businesscontroller-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.businesscontroller-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.businesscontroller-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.businesscontroller-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.businesscontroller-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.businesscontroller-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.businesscontroller-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 6">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/jonas-pregartner.jpg" :alt="$t('berufs-bilder.datenjournalist-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-right bg-blue-60perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.datenjournalist-zitat') }}<span class="name">Jonas Pregartner <span class="firma">Kleine Zeitung</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.datenjournalist-hl') }}</h2>
                      <span>Jonas Pregartner, Kleine Zeitung</span>
                      <h3>{{ $t('berufs-bilder.datenjournalist-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.datenjournalist-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.datenjournalist-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.datenjournalist-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.datenjournalist-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.datenjournalist-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.datenjournalist-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.datenjournalist-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 7">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/dominik-schmied.jpg" :alt="$t('berufs-bilder.projektmanager-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="bottom-right bg-violet-50perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" /><div v-html="$t('berufs-bilder.projektmanager-zitat')"></div><span class="name">Dominik Schmied<span class="firma">Kleine Zeitung</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.projektmanager-hl') }}</h2>
                      <span>Dominik Schmied, Kleine Zeitung</span>
                      <h3>{{ $t('berufs-bilder.projektmanager-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.projektmanager-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.projektmanager-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.projektmanager-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.projektmanager-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.projektmanager-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.projektmanager-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.projektmanager-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 8">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/lukas-kohlmair.jpg" :alt="$t('berufs-bilder.videocutter-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="bottom-left big-h4 bg-coral color-fff">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon zitat-icon-fff" />{{ $t('berufs-bilder.videocutter-zitat') }}<span class="name">Lukas Kohlmaier<span class="firma">MM </span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.videocutter-hl') }}</h2>
                      <span>Lukas Kohlmaier, MM </span>
                      <h3>{{ $t('berufs-bilder.videocutter-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.videocutter-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.videocutter-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.videocutter-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.videocutter-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.videocutter-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.videocutter-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.videocutter-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 9">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/klaus-krall.jpg" :alt="$t('berufs-bilder.regionalverkaufer-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-left bg-mint">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.regionalverkaufer-zitat') }}<span class="name">Klaus Krall <span class="firma">Kleine Zeitung</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2 v-html="$t('berufs-bilder.regionalverkaufer-hl')"></h2>
                      <span>Klaus Krall, Kleine Zeitung</span>
                      <h3>{{ $t('berufs-bilder.regionalverkaufer-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.regionalverkaufer-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.regionalverkaufer-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.regionalverkaufer-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.regionalverkaufer-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.regionalverkaufer-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.regionalverkaufer-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.regionalverkaufer-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <!--
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 10">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/benedikt-sassmann.jpg" :alt="$t('berufs-bilder.leserervice-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="bottom-left bg-blue-60perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.leserervice-zitat') }}<span class="name">Benedikt Sassmann<span class="firma">Styria Marketing Services</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.leserervice-hl') }}</h2>
                      <span>Benedikt Sassmann, Styria Marketing Services</span>
                      <h3>{{ $t('berufs-bilder.leserervice-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.leserervice-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.leserervice-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.leserervice-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.leserervice-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.leserervice-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.leserervice-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.leserervice-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            -->
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 11">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/larissa-eberhardt.jpg" :alt="$t('berufs-bilder.redakteur-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="bottom-left bg-violet-50perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.redakteur-zitat') }}<span class="name">Larissa Eberhardt <span class="firma">Kleine Zeitung</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.redakteur-hl') }}</h2>
                      <span>Larissa Eberhardt, Kleine Zeitung</span>
                      <h3>{{ $t('berufs-bilder.redakteur-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.redakteur-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.redakteur-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.redakteur-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.redakteur-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.redakteur-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.redakteur-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.redakteur-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 12">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/simone-ruchesi.jpg" :alt="$t('berufs-bilder.grafikdesigner-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-left bg-coral color-fff">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon zitat-icon-fff" />{{ $t('berufs-bilder.grafikdesigner-zitat') }}<span class="name">Simone Ruchesi<span class="firma">Styria Media Design</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.grafikdesigner-hl') }}</h2>
                      <span>Simone Ruchesi, Styria Media Design</span>
                      <h3>{{ $t('berufs-bilder.grafikdesigner-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.grafikdesigner-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.grafikdesigner-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.grafikdesigner-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.grafikdesigner-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.grafikdesigner-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.grafikdesigner-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.grafikdesigner-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 13">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/bernhard-benke.jpg" :alt="$t('berufs-bilder.frontenddeveloper-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-left bg-mint">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.frontenddeveloper-zitat') }}<span class="name">Bernhard Benke<span class="firma">Styria Digital Services</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.frontenddeveloper-hl') }}</h2>
                      <span>Bernhard Benke, Styria Digital Services</span>
                      <h3>{{ $t('berufs-bilder.frontenddeveloper-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.frontenddeveloper-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.frontenddeveloper-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.frontenddeveloper-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.frontenddeveloper-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.frontenddeveloper-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.frontenddeveloper-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.frontenddeveloper-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <!--
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 14">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/vanessa-waltenspiel.jpg" :alt="$t('berufs-bilder.onlinemarketing-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-left bg-blue-60perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.onlinemarketing-zitat') }}<span class="name">Vanessa Waltenspiel<span class="firma">missMedia</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.onlinemarketing-hl') }}</h2>
                      <span>Vanessa Waltenspiel, missMedia</span>
                      <h3>{{ $t('berufs-bilder.onlinemarketing-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.onlinemarketing-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.onlinemarketing-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.onlinemarketing-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.onlinemarketing-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.onlinemarketing-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.onlinemarketing-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.onlinemarketing-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            -->
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 15">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/thomas-plauder.jpg" :alt="$t('berufs-bilder.printeditor-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="bottom-right bg-violet-50perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.printeditor-zitat') }}<span class="name">Thomas Plauder<span class="firma">Kleine Zeitung</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.printeditor-hl') }}</h2>
                      <span>Thomas Plauder, Kleine Zeitung</span>
                      <h3>{{ $t('berufs-bilder.printeditor-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.printeditor-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.printeditor-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.printeditor-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.printeditor-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.printeditor-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.printeditor-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.printeditor-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 16">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/martin-wimberger.jpg" :alt="$t('berufs-bilder.telefonverkaeufer-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-left bg-coral color-fff"><Icon :path="mdiFormatQuoteClose" class="zitat-icon zitat-icon-fff" />{{ $t('berufs-bilder.telefonverkaeufer-zitat') }}<span class="name">Martin Wimberger <span class="firma">Styria Call &amp; Mail</span></span></h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.telefonverkaeufer-hl') }}</h2>
                      <span>Martin Wimberger, Styria Call &amp; Mail</span>
                      <h3>{{ $t('berufs-bilder.telefonverkaeufer-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.telefonverkaeufer-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.telefonverkaeufer-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.telefonverkaeufer-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.telefonverkaeufer-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.telefonverkaeufer-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.telefonverkaeufer-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.telefonverkaeufer-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 17">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/julian-friedl.jpg" :alt="$t('berufs-bilder.radiomoderator-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-left bg-mint">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.radiomoderator-zitat') }}<span class="name">Julian Friedl <span class="firma">Antenne Kärnten</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.radiomoderator-hl') }}</h2>
                      <span>Julian Friedl, Antenne Kärnten</span>
                      <h3>{{ $t('berufs-bilder.radiomoderator-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.radiomoderator-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.radiomoderator-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.radiomoderator-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.radiomoderator-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.radiomoderator-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.radiomoderator-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.radiomoderator-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 18">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/matthias-dungel.jpg" :alt="$t('berufs-bilder.adoperationmanager-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-left bg-blue-60perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.adoperationmanager-zitat') }}<span class="name">Matthias Dungel <span class="firma">COPE</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.adoperationmanager-hl') }}</h2>
                      <span>Matthias Dungel, COPE</span>
                      <h3>{{ $t('berufs-bilder.adoperationmanager-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.adoperationmanager-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.adoperationmanager-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.adoperationmanager-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.adoperationmanager-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.adoperationmanager-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.adoperationmanager-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.adoperationmanager-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 19">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/karina-kleewein.jpg" :alt="$t('berufs-bilder.kreditorenbuchhalter-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-right bg-violet-50perc">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('berufs-bilder.kreditorenbuchhalter-zitat') }}<span class="name">Karina Kleewein <span class="firma">Styria Fides</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.kreditorenbuchhalter-hl') }}</h2>
                      <span>Karina Kleewein, Styria Fides</span>
                      <h3>{{ $t('berufs-bilder.kreditorenbuchhalter-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.kreditorenbuchhalter-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.kreditorenbuchhalter-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.kreditorenbuchhalter-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.kreditorenbuchhalter-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.kreditorenbuchhalter-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.kreditorenbuchhalter-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.kreditorenbuchhalter-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-tab">
              <div class="tab-inner" v-if="active == 20">
                <div class="berufsbilder-box">
                  <div class="img-box">
                    <img src="@/assets/img/karriere/david-matausek.jpg" :alt="$t('berufs-bilder.informationsecurityofficer-hl')" class="img-fluid shadow margin-tb-s" />
                    <h4 class="top-right bg-coral color-fff">
                      <Icon :path="mdiFormatQuoteClose" class="zitat-icon zitat-icon-fff" />{{ $t('berufs-bilder.informationsecurityofficer-zitat') }}<span class="name">David Matausek <span class="firma">Styria IT</span></span>
                    </h4>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2>{{ $t('berufs-bilder.informationsecurityofficer-hl') }}</h2>
                      <span>David Matausek, Styria IT</span>
                      <h3>{{ $t('berufs-bilder.informationsecurityofficer-hl-v1') }}</h3>
                      <p>{{ $t('berufs-bilder.informationsecurityofficer-txt-v1') }}</p>
                      <h3>{{ $t('berufs-bilder.informationsecurityofficer-hl-v2') }}</h3>
                      <p>{{ $t('berufs-bilder.informationsecurityofficer-txt-v2') }}</p>
                      <h3>{{ $t('berufs-bilder.informationsecurityofficer-hl-v3') }}</h3>
                      <p>{{ $t('berufs-bilder.informationsecurityofficer-txt-v3') }}</p>
                      <h3>{{ $t('berufs-bilder.informationsecurityofficer-hl-v4') }}</h3>
                      <p v-html="$t('berufs-bilder.informationsecurityofficer-txt-v4')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="btn-std">
            <a href="#slider-beruf-top" v-smooth-scroll="{ offset: -200 }"
              ><span class="txt" v-html="$t('berufs-bilder.weitere-berufsbilder')"> </span> <span class="icon-img-top"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Slider from '../../../components/Slider.vue';
import SliderItem from '../../../components/SliderItem.vue';

import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Jobvielfalt in der STYRIA | Styria Media Group',
      keywords: ['berufsbild', 'jobvielfalt', 'mitarbeiter', 'jobangebote', 'traumjob'],
      description: 'Die STYRIA bietet eine Vielfalt an Jobs: Radiomoderator:in, Grafikdesigner:in, Reporter:in, Projektmanager:in, Datenjournalist:in und vieles mehr.',
      image: require('@/assets/img/og/karriere.png'),
    },
    en: {
      title: 'Job variety | Styria Media Group',
      keywords: ['job profile', 'job diversity', 'employee', 'job offer', 'dream job', 'styria'],
      description: 'STYRIA offers a variety of jobs: radio host, graphic designer, reporter, project manager, data journalist and much more.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
    Slider,
    SliderItem,
  },
  setup() {
    const active = ref(18);

    const setActive = (value: number) => {
      active.value = value;
    };
    return {
      setActive,
      active,
      mdiChevronUp,
      mdiFormatQuoteClose,
    };
  },
});
</script>
